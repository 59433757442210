import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC, useState } from 'react';
import * as style from './index.module.scss';
import ChapterViewer from '../ChapterViewer';

type Props = Omit<PageProps, 'children'> & { children: JSX.Element };
const ArticleLayout: FC<Props> = ({ children, location }) => {
    const [isAsideOpen, setAsideOpenState] = useState(false);

    return (
        <div className={style.div}>
            <main>{children}</main>
            <aside className={isAsideOpen ? style.show : 0}>
                <div
                    className={`${style.imageContainer} ${isAsideOpen ? '' : style.show}`}
                    onClick={() => setAsideOpenState(true)}
                >
                    <StaticImage
                        className={style.image}
                        src="../../images/list.png"
                        alt="list icon"
                        title="Open menu"
                        loading="eager"
                    />
                </div>
                <div
                    className={`${style.imageContainer} ${isAsideOpen ? style.show : ''}`}
                    onClick={() => setAsideOpenState(false)}
                >
                    <StaticImage
                        className={style.image}
                        src="../../images/cross.png"
                        alt="cross icon"
                        title="Close menu"
                        loading="eager"
                    />
                </div>
                <ChapterViewer currentPath={location.pathname} />
            </aside>
        </div>
    );
};
export default ArticleLayout;
