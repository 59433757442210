type Node<T> = T & { items?: Array<Node<T>> };

export function toTree<T extends { depth: number }>(
    array: Array<Exclude<T, { items: unknown }>>,
): Array<T & { items: Array<T> }> {
    const tree: Array<T & { items: Array<T> }> = [];
    for (const element of array) {
        if ((process.env.NODE_ENV === 'development' && element.depth < 0) || element.depth > 2) {
            throw new Error(
                `The depth of node in the tree is out of range. | 0 < ${element.depth} < 2`,
            );
        }

        if (element.depth === 0) {
            tree.push({
                ...element,
                depth: undefined,
            });
        } else {
            const parent = tree[tree.length - 1];
            if (!parent.items) parent.items = [];
            parent.items.push(element);
        }
    }
    return tree;
}

export function collapse<T>(
    tree: Array<Node<Exclude<T, { depth: unknown }>>>,
): Array<T & { depth: number }> {
    const collapsedTree: Array<T & { depth: number }> = [];
    tree.forEach((node) => {
        collapsedTree.push({
            ...node,
            depth: 0,
        });
        if (node.items) {
            node.items.forEach((node) => {
                if (node.items && process.env.NODE_ENV === 'development') {
                    throw new Error(`The depth of node in the tree is out of range. | depth > 1`);
                }

                collapsedTree.push({
                    ...node,
                    depth: 1,
                });
            });
        }
    });
    return collapsedTree;
}

export function sortNode<T extends { order: number }>(
    tree: Array<T & { items: Array<T> }>,
): Array<T & { items: Array<T> }> {
    const sortDescending = (node1: T, node2: T) => node1.order - node2.order;
    tree.sort(sortDescending);
    tree.forEach((node) => {
        if (node.items) {
            node.items.sort(sortDescending);
        }
    });
    return tree;
}
