export function getLanguage<Language extends { readonly id: string; readonly path: string }>(
    path: string,
    languages: ReadonlyArray<Language>,
): Language {
    let defaultLanguage: Language;
    for (const language of languages) {
        if (!language.id || (!language.path && language.path !== '')) {
            throw Error('Language configuration error, please add "id" and "path" in graphql');
        }

        if (language.id === 'en') defaultLanguage = language;
        else if (path.match(new RegExp(`${language.path}$`))) return language;
    }
    return defaultLanguage!;
}
