import { useStaticQuery, graphql } from 'gatsby';
import React, { FC } from 'react';
import ChapterGroup from './ChapterGroup';
import ChapterLink from './ChapterLink';
import * as style from './index.module.scss';
import { getLanguage } from '../../tools/Language';
import { sortNode, toTree } from '../../tools/Tree';
import InformationPanel from '../InformationPanel';

type Props = {
    currentPath: string;
};
const ChapterViewer: FC<Props> = ({ currentPath }) => {
    const { site, allMdx }: Queries.ChapterViewerQuery = useStaticQuery(graphql`
        query ChapterViewer {
            site {
                siteMetadata {
                    languages {
                        id
                        path
                        contents
                    }
                }
            }
            allMdx(
                sort: [{ fields: { purePath: ASC } }]
                filter: { fields: { isPublished: { eq: true } } }
            ) {
                nodes {
                    frontmatter {
                        title
                    }
                    fields {
                        contents
                        depth
                        language
                        order
                        path
                    }
                }
            }
        }
    `);
    const contentsName = currentPath.match(/^\/([^/]+)/)![1];
    const language = getLanguage(currentPath, site!.siteMetadata.languages);
    const filteredMarkdowns = allMdx.nodes.filter(
        (node) => node.fields.contents === contentsName && node.fields.language === language.id,
    );
    const contents = {
        title: filteredMarkdowns[0].frontmatter.title,
        path: `/${contentsName}`,
    };
    const chapters = sortNode(
        toTree(
            filteredMarkdowns.slice(1).map((node) => ({
                title: node.frontmatter.title,
                depth: node.fields.depth - 1,
                path: node.fields.path,
                order: node.fields.order,
            })),
        ),
    );

    return (
        <nav className={style.nav}>
            <div className={style.links}>
                <ChapterLink
                    to={`/contents${language.path}`}
                    currentPath={currentPath}
                    className={style.link}
                >
                    <span>{language.contents}</span>
                </ChapterLink>
                <ChapterLink
                    to={`${contents.path}${language.path}`}
                    currentPath={currentPath}
                    className={style.link}
                >
                    <span>{contents.title}</span>
                </ChapterLink>
                {chapters.map((chapter) => (
                    <ChapterGroup
                        key={`chapter_${chapter.title}`}
                        chapter={chapter}
                        currentPath={currentPath}
                    />
                ))}
            </div>
            <div className={style.bottom}>
                <InformationPanel currentPath={currentPath} />
            </div>
        </nav>
    );
};
export default ChapterViewer;
