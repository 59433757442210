import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import * as style from './index.module.scss';
import { getLanguage } from '../../tools/Language';
import Copyright from '../Copyright';

type Props = {
    currentPath: string;
};
const InformationPanel: FC<Props> = ({ currentPath }) => {
    const { site }: Queries.NavigationQuery = useStaticQuery(graphql`
        query Navigation {
            site {
                siteMetadata {
                    languages {
                        id
                        name
                        path
                    }
                }
            }
        }
    `);
    const languages = site!.siteMetadata.languages;

    const navigateTo = (fromLanguagePath: string, toLanguagePath: string) => {
        navigate(currentPath.replace(new RegExp(`${fromLanguagePath}$`), toLanguagePath));
    };

    return (
        <nav className={style.nav}>
            <div>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://SNinjo.com" target="_blank" className={style.link}>
                    <StaticImage
                        className={style.image}
                        src="../../images/sninjo.png"
                        alt="SNinjo logo"
                        title="SNinjo's Personal Website"
                        loading="eager"
                    />
                </a>
                <Link to="/" className={style.link}>
                    <StaticImage
                        className={style.image}
                        src="../../images/blog.png"
                        alt="SNinjo Blog"
                        title="SNinjo Blog"
                        loading="eager"
                    />
                </Link>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://SNinjo.com/contact" target="_blank" className={style.link}>
                    <StaticImage
                        className={style.image}
                        src="../../images/email.png"
                        alt="contact icon"
                        title="Contact Information"
                        loading="eager"
                    />
                </a>

                <select
                    defaultValue={getLanguage(currentPath, languages).path}
                    onChange={(event) =>
                        navigateTo(getLanguage(currentPath, languages).path, event.target.value)
                    }
                >
                    {languages.map((language, index) => (
                        <option key={`Language${index}`} value={language.path}>
                            {language.name}
                        </option>
                    ))}
                </select>
            </div>
            <Copyright currentPath={currentPath} />
        </nav>
    );
};
export default InformationPanel;
