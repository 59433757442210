import { StaticImage } from 'gatsby-plugin-image';
import React, { FC, useState } from 'react';
import * as style from './index.module.scss';
import ChapterLink, { LinkHeight } from '../ChapterLink';

type Chapter = {
    title: string;
    path: string;
    items?: Array<{
        title: string;
        path: string;
    }>;
};
type Props = {
    chapter: Chapter;
    currentPath: string;
};
const ChapterGroup: FC<Props> = ({ chapter, currentPath }) => {
    const [isOpen, setOpenState] = useState(currentPath.includes(chapter.path));

    return (
        <div className={style.div}>
            <ChapterLink to={chapter.path} currentPath={currentPath} className={style.link}>
                {chapter.title}
            </ChapterLink>
            {chapter.items ? (
                <>
                    <button
                        style={{
                            width: LinkHeight,
                            height: LinkHeight,
                        }}
                        className={isOpen ? style.downward : ''}
                        onClick={() => setOpenState(!isOpen)}
                    >
                        <StaticImage
                            className={style.image}
                            src="../../../images/curvedArrow.png"
                            alt="collapse chapter icon"
                            title="Open/collapse chapter"
                            loading="lazy"
                        />
                    </button>
                    <div
                        style={{
                            height: isOpen ? LinkHeight * chapter.items.length : 0,
                        }}
                        className={style.subchapters}
                    >
                        {chapter.items.map((subchapter) => (
                            <ChapterLink
                                to={subchapter.path}
                                key={`subchapter_${subchapter.title}`}
                                currentPath={currentPath}
                                className={style.link}
                            >
                                {subchapter.title}
                            </ChapterLink>
                        ))}
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
export default ChapterGroup;
