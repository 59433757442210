import { Link } from 'gatsby';
import React, { FC, ReactNode } from 'react';
import * as style from './index.module.scss';

export const LinkHeight = 36;

type Props = {
    to: string;
    currentPath: string;
    children: ReactNode;
    className?: string;
};
const ChapterLink: FC<Props> = ({ to, currentPath, children, className = '' }) => {
    return (
        <Link
            to={to}
            style={{ height: LinkHeight }}
            className={`
                ${className}
                ${style.link}
                ${currentPath.match(new RegExp(`${to}/?$`)) ? style.current : ''}
            `}
        >
            {children}
        </Link>
    );
};
export default ChapterLink;
