import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { FC } from 'react';
import * as style from './index.module.scss';
import { getLanguage } from '../../tools/Language';

type Props = {
    currentPath: string;
};
const Copyright: FC<Props> = ({ currentPath }) => {
    const { site }: Queries.CopyrightQuery = useStaticQuery(graphql`
        query Copyright {
            site {
                siteMetadata {
                    languages {
                        id
                        name
                        path
                    }
                }
            }
        }
    `);
    const languages = site!.siteMetadata.languages;

    return (
        <Link
            to={`/Preface/Copyright${getLanguage(currentPath, languages).path}`}
            className={style.link}
        >
            <p>SNinjo 2023 © all rights reserved</p>
        </Link>
    );
};
export default Copyright;
